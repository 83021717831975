<template>
  <div>
    <div class="modules">
      <img src="../assets/img/contact_bg.svg" alt="" class="contact_bg" />
      <div class="floor1 clearfix">
        <div class="title1">Our modules</div>
        <div class="title2">Our modules</div>
        <img src="../assets/img/Modules.svg" alt="" class="floor1_img" />
      </div>
      <div class="floor2">
        <div class="list mr32">
          <img src="../assets/img/Group2.svg" alt="" />
          <div class="title">Membership</div>
          <div class="txt">
            This enables Client and Members Registration, bio data information
            mangement with reports.
          </div>
        </div>
        <div class="list">
          <img src="../assets/img/Group3.svg" alt="" />
          <div class="title">Group Membership</div>
          <div class="txt">
            This enables group and Members Registration, bio data information
            mangement with reports.
          </div>
        </div>
        <div class="list mr32">
          <img src="../assets/img/notification-module2.svg" alt="" />
          <div class="title">API Integration</div>
          <div class="txt">
            Our system is built in a way to that enables it to easily aggregate
            to other third parties or modules.
          </div>
        </div>
        <div class="list">
          <img src="../assets/img/notification-module.svg" alt="" />
          <div class="title">SMS & Email Notification</div>
          <div class="txt">
            Broadcasting SMS and emails to members and customers in the event of
            a transaction or a meeting e.t.c.
          </div>
        </div>
        <div class="list mr32">
          <img src="../assets/img/savings.svg" alt="" />
          <div class="title">Savings/Shares</div>
          <div class="txt">
            Monitors members deposits and savings accounts, all transactions
            history for deposits/withdrawals linked with financials.
          </div>
        </div>
        <div class="list">
          <img src="../assets/img/loans-icon.svg" alt="" />
          <div class="title">Loans</div>
          <div class="txt">
            From Loan application, Approval,disbursements, amortization schedule
            and Repayments with reports.
          </div>
        </div>
        <div class="list mr32">
          <img src="../assets/img/mobile-app-icon.svg" alt="" />
          <div class="title">App and USSD</div>
          <div class="txt">
            Members can be able to access their data and reports through the APP
            and USSD.
          </div>
        </div>
        <div class="list">
          <img src="../assets/img/divident-icon.svg" alt="" />
          <div class="title">Share and Dividends</div>
          <div class="txt">
            Handles all transactions involving share purchase, transfer, value
            changes and dividends with associated reports.
          </div>
        </div>
        <div class="list mr32">
          <img src="../assets/img/Group1.svg" alt="" />
          <div class="title">Collections/Payments</div>
          <div class="txt">
            Broadcasting SMS and emails to members and customers in the event of
            a transaction or a meeting etc
          </div>
        </div>
        <div class="list">
          <img src="../assets/img/accounting-icon.svg" alt="" />
          <div class="title">Accounting</div>
          <div class="txt">
            Handles all financial transactions from journal entries, approval,
            Chart of accounts etc with reports.
          </div>
        </div>
        <div class="list mr32">
          <img src="../assets/img/reporting-icon.svg" alt="" />
          <div class="title">Reporting</div>
          <div class="txt">
            The Reporting Module was designed to provide a feature-rich and
            user-friendly web interface for managing reports.
          </div>
        </div>
      </div>
      <contact></contact>
    </div>
    <div class="h5">
      <img src="../assets/img/h5/Group_160.png" alt="" class="bg" />
      <div class="list">
        <img src="../assets/img/h5/Group1.svg" alt="" />
        <div class="txt_box">
          <div class="title">Membership</div>
          <div class="txt">
            This enables Client and Members Registration, bio data information
            mangement with reports.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group2.svg" alt="" />
        <div class="txt_box">
          <div class="title">Group Membership</div>
          <div class="txt">
            This enables group and Members Registration, bio data information
            mangement with reports.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group3.svg" alt="" />
        <div class="txt_box">
          <div class="title">API Integration</div>
          <div class="txt">
            Our system is built in a way to that enables it to easily aggregate
            to other third parties or modules.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group4.svg" alt="" />
        <div class="txt_box">
          <div class="title">SMS & Email Notification</div>
          <div class="txt">
            Broadcasting SMS and emails to members and customers in the event of
            a transaction or a meeting e.t.c.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group5.svg" alt="" />
        <div class="txt_box">
          <div class="title">Savings/Shares</div>
          <div class="txt">
            Monitors members deposits and savings accounts, all transactions
            history for deposits/withdrawals linked with financials.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group6.svg" alt="" />
        <div class="txt_box">
          <div class="title">Loans</div>
          <div class="txt">
            From Loan application, Approval,disbursements, amortization schedule
            and Repayments with reports.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group7.svg" alt="" />
        <div class="txt_box">
          <div class="title">App and USSD</div>
          <div class="txt">
            Members can be able to access their data and reports through the APP
            and USSD.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group8.svg" alt="" />
        <div class="txt_box">
          <div class="title">Share and Dividends</div>
          <div class="txt">
            Handles all transactions involving share purchase, transfer, value
            changes and dividends with associated reports.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group9.svg" alt="" />
        <div class="txt_box">
          <div class="title">Collections/Payments</div>
          <div class="txt">
            Broadcasting SMS and emails to members and customers in the event of
            a transaction or a meeting etc
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group10.svg" alt="" />
        <div class="txt_box">
          <div class="title">Accounting</div>
          <div class="txt">
            Handles all financial transactions from journal entries, approval,
            Chart of accounts etc with reports.
          </div>
        </div>
      </div>
      <div class="list">
        <img src="../assets/img/h5/Group11.svg" alt="" />
        <div class="txt_box">
          <div class="title">Reporting</div>
          <div class="txt">
            The Reporting Module was designed to provide a feature-rich and
            user-friendly web interface for managing reports.
          </div>
        </div>
      </div>
      <contact></contact>
    </div>
  </div>
</template>
<script>
import Contact from "@/components/Contact";
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit("SetActivePage", "modules");
  },
  components: {
    Contact,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .modules {
    position: relative;
    width: 100%;
    min-width: 1200px;
    .contact_bg {
      width: 94.3%;
      position: absolute;
      left: 0;
      top: -160px;
      z-index: 1;
    }
    .floor1 {
      position: relative;
      z-index: 2;
      margin-bottom: 200px;
      .title1 {
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 80px;
        color: #ffffff;
        padding: 232px 0 0 14.6%;
        float: left;
        position: relative;
        z-index: 3;
      }
      .title2 {
        font-style: normal;
        font-weight: bold;
        font-size: 130px;
        height: 172px;
        color: #ffffff;
        opacity: 0.15;
        position: absolute;
        z-index: 2;
        left: 8.7%;
        top: 186px;
      }
      .floor1_img {
        width: 55%;
        float: right;
        margin-top: -107px;
      }
    }
    .floor2 {
      width: 100%;
      padding: 0 10%;
      box-sizing: border-box;
      .list {
        width: calc(50% - 16px);
        height: 520px;
        margin-bottom: 50px;
        border: 2px solid #cc3e91;
        box-sizing: border-box;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 60px 2% 0;
        display: inline-block;
        vertical-align: top;
        &.mr32 {
          margin-right: 32px;
        }
        img {
          height: 120px;
          display: block;
          margin: 0 auto;
        }
        .title {
          height: 80px;
          line-height: 80px;
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          color: #622b83;
          text-align: center;
          margin: 30px 0;
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 40px;
          color: #4d4d4d;
          text-align: center;
        }
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .modules {
    display: none;
  }
  .h5 {
    .bg {
      width: 100%;
      display: block;
      display: block;
      margin-top: -86px;
      z-index: 2;
      margin-bottom: 30px;
    }
    .list {
      width: 94%;
      margin: 0 auto 12px;
      border: 1px solid #cc3e91;
      padding: 25px 16px;
      box-sizing: border-box;
      border-radius: 8px;
      img {
        width: 78px;
        height: 78px;
        display: inline-block;
        vertical-align: top;
        margin-right: 12px;
      }
      .txt_box {
        width: calc(100% - 90px);
        display: inline-block;
        vertical-align: top;
        .title {
          color: #622b83;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 6px;
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #4d4d4d;
        }
      }
    }
  }
}
</style>
